import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Container, Stack, Flex, SimpleGrid } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import CustomButton from '../components/custom-button';
import '../styles/nutrients.scss';

const Nutrients = () => (
  <Layout>
    <SEO
      title="Optimize Fertilizer Nutrients | Crystal Green | Ostara"
      description="Learn how to achieve the optimal mix of fertilizer nutrients and why phosphorus, magnesium and nitrogen are essential ingredients in Crystal Green fertilizers."
      image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      keywords="Fertilizer Nutrients"
    />
    <main>
      <Box w="100%" p={4} className="product-hero-container">
        <Container maxW={1400}>
          <Box
            display="flex"
            flexDirection={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            paddingTop={[0, 0, 10, 50]}
          >
            <Box w={['100%', '100%', '100%', '46.5%']}>
              <Stack marginTop={[0, 0, 0, 20]} maxW="50rem" spacing={6}>
                <h1>Nutrients</h1>
                <p>
                  Learn why the phosphate, magnesium and nitrogen in Crystal
                  Green are essential to crop health and yield performance.
                </p>
              </Stack>
            </Box>
            <Stack
              spacing={[4, 6, 4, 6, 8]}
              w={['100%', '100%', '100%', '46.5%']}
            >
              <Box className="benefits-hero-img-container">
                <StaticImage
                  className="hero-img"
                  placeholder="transparent"
                  src="../images/nutrient.hero-img-min.jpg"
                  alt="Ostara hero img"
                />
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>

      <Box w="100%" p={4} className="nutrients-body-container">
        <Container
          className="nutrients-body-content-1--container"
          maxW="container.md"
        >
          <div className="nutrients-body-content-1--heading">
            <h3>Achieve the Optimal Delivery of Fertilizer Nutrients</h3>
            <p>
              Crystal Green delivers phosphate, nitrogen and magnesium (5-28-0
              with 10% Mg) more efficiently than conventional phosphate
              fertilizers which enhances plant growth and yield.
            </p>
          </div>
        </Container>
        <Container className="key-top-box" maxW={1400} />
        <Container className="nutrients-body-2--container" maxW={1400}>
          <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={24}>
            <Flex direction="column" className="nutrients-body-content-2--card">
              <StaticImage
                placeholder="transparent"
                src="../images/nutrients-potato-edited.png"
                alt="Ostara img"
              />
              <h3>Phosphate</h3>
              <p>
                Phosphate plays a huge role in how carbon is captured and
                converted by plants into carbohydrates — one of the most
                important determinants of yield.
              </p>
              <CustomButton maxW={180} to="/nutrients/phosphate">
                LEARN MORE
              </CustomButton>
            </Flex>
            <Flex direction="column" className="nutrients-body-content-2--card">
              <StaticImage
                placeholder="transparent"
                src="../images/nitrogen.png"
                alt="Ostara img"
              />
              <h3>Nitrogen</h3>
              <p>
                Nitrogen is an essential component of chlorophyll, which allows
                the plant to capture light energy via photosynthesis, driving
                growth and yield.
              </p>
              <CustomButton to="/nutrients/nitrogen" maxW={180}>
                LEARN MORE
              </CustomButton>
            </Flex>
            <Flex direction="column" className="nutrients-body-content-2--card">
              <StaticImage
                placeholder="transparent"
                src="../images/nutrients-mg-banner.png"
                alt="Ostara img"
              />
              <h3>Magnesium</h3>
              <p>
                Magnesium is not only the core of every chlorophyll molecule,
                but it also plays a major role in moving carbohydrates from
                source tissues, such as leaves to sink tissue. Sufficient
                magnesium results in high yield results.
              </p>
              <CustomButton to="/nutrients/magnesium" maxW={180}>
                LEARN MORE
              </CustomButton>
            </Flex>
          </SimpleGrid>
        </Container>
      </Box>
      <Container className="body-cta-container" maxW={1400}>
        <Box>
          <Link to="/contact">Let’s Talk About Your Nutrient Challenges</Link>
        </Box>
      </Container>
    </main>
  </Layout>
);

export default Nutrients;
